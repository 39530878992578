(function($) {
    "use strict"; // Start of use strict

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        //console.log($anchor.attr('href'));

        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
        target: '.navbar-fixed-top',
        offset: 51
    });

    // Closes the Responsive Menu on Menu Item Click
    $('.navbar-collapse ul li a:not(.dropdown-toggle)').click(function() {
        $('.navbar-toggle:visible').click();
    });

    // Offset for Main Navigation
    $('#mainNav').affix({
        offset: {
            top: 100
        }

    })

    $('.navbar-default .navbar-header .navbar-brand > img').affix({
        offset: {
            top: 100
        }

    })

    // Initialize and Configure Scroll Reveal Animation
    window.sr = ScrollReveal();
    sr.reveal('.sr-icons', {
        duration: 600,
        scale: 0.3,
        distance: '0px'
    }, 200);
    sr.reveal('.sr-button', {
        duration: 1000,
        delay: 200
    });
    sr.reveal('.sr-contact', {
        duration: 600,
        scale: 0.3,
        distance: '0px'
    }, 300);


    
        $(window).scroll(function(e){
          


            var scrolled = $(window).scrollTop();
            

            if($('#plans').visible(true, false, 'vertical'))
            {
                var valor = -(scrolled / 47);
               //console.log(valor); 
               //$('.bg-plans').css('background-position-y',95 + valor+'%');
               var cambio = 95 + valor;
               $('.bg-plans').css('background-position','50% ' + cambio + '%');
            }
            else
            {
                //$('.bg-plans').css('background-position-y','95%');
                $('.bg-plans').css('background-position','50% 95%');
            }

            if($('#clients').visible(true, false, 'vertical'))
            {
                var valor = -(scrolled / 47);
                var cambio = 90 + valor
               //console.log(valor); 

               //$('.section-clients').css('background-position-y',90 + valor+'%');
                $('.section-clients').css('background-position', '50% ' + cambio +'%');
            }
            else
            {
                //$('.section-clients').css('background-position-y','50%');
                $('.section-clients').css('background-position','50% 50%');
            }
            
            //console.log("plan es: " + $('#plans').visible(true, false, 'vertical'));


        });


        $("#slide-key-features").owlCarousel({

              navigation : false,
               navigationText: [
              '<i class="zmdi zmdi-chevron-left"></i>',
              '<i class="zmdi zmdi-chevron-right"></i>'
              ],
              slideSpeed : 300,
              paginationSpeed : 400,
              singleItem : true

              // "singleItem:true" is a shortcut for:
              // items : 1, 
              // itemsDesktop : false,
              // itemsDesktopSmall : false,
              // itemsTablet: false,
              // itemsMobile : false

          });

        $("#slide-clients").owlCarousel({

            autoPlay: 11000,
            slideSpeed : 300,
            paginationSpeed : 400,
            singleItem : true



        });


        $('#btn-request-demo').on('click', function(event){
            event.preventDefault();
            
            var check = 0;

            $("#form-request-demo :input, #form-request-demo select").each(function(){
                var field = $(this).val();
                if(field.trim() != "")
                {
                    check++;
                }
            });


            if(check == 5)
            {
                $(this).hide();
                $("#form-request-demo .message").html('Enviando solicitud...');
                $("#form-request-demo .message").show();

                var form = $('#form-request-demo').serialize();
                 

                $.ajax({
                    url: 'request-demo',
                    type: 'post',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: form,
                    success: function (data) {

                        if(data.status == 'ok')
                        {

                            $("#form-request-demo :input").each(function(){
                                if($(this).attr('name') != 'type' )
                                {
                                    $(this).val("");
                                }
                                
                            });

                        }
                       
                            $("#form-request-demo .message").html(data.message);
                            $("#form-request-demo .message").fadeIn().delay(3000).fadeOut();
                            $('#btn-request-demo').show();
                     
                    }
                });   
            }
            else
            {
                $("#form-request-demo .message").html('Debe completar todos los campos.');
                $("#form-request-demo .message").fadeIn().delay(3000).fadeOut();
            }


        });

        $('#btn-send-contact').on('click', function(event){
            event.preventDefault();
            
            var check = 0;

            $("#form-send-contact :input").each(function(){
                var field = $(this).val();

                if(field.trim() != "")
                {
                    check++;
                }
            });


            if(check == 4)
            {
                $(this).hide();
                $("#form-send-contact .message").html('Enviando...');
                $("#form-send-contact .message").show();

                var form = $('#form-send-contact').serialize();
                 

                $.ajax({
                    url: 'mail',
                    type: 'post',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: form,
                    success: function (data) {

                        if(data == 'ok')
                        {
                            $("#form-send-contact :input").each(function(){
                                $(this).val("");
                            });

                            $("#form-send-contact .message").html('El mensaje se ha enviado, pronto nos comunicaremos con usted.');
                            $("#form-send-contact .message").fadeIn().delay(3000).fadeOut();
                            $('#btn-send-contact').show();
                        }
                    }
                });   
            }
            else
            {
                $("#form-send-contact .message").html('Debe completar todos los campos.');
                $("#form-send-contact .message").fadeIn().delay(3000).fadeOut();
            }


        });

})(jQuery); // End of use strict
